











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.operationStudent {
  .itemLabel {
    min-width: 5rem;
  }
  .ci-full {
    .el-input--suffix .el-input__inner {
      padding-right: 3px;
    }
    .el-input {
      input {
        width: 100% !important;
      }
    }
    .smallselect {
      input {
        width: 100% !important;
      }
    }
  }
}
.el-select__tags-text {
  min-width: 2rem !important;
}
.el-selectBox {
  .el-select {
    // min-width: 15rem;
    .el-tag {
      min-width: 10px !important;
    }
  }
}
